/*
Document   : commons/commonhooks
Created on : Sat 05 Aug 2023 17∶57∶38
Author     : Manasseh Achirka
 */

import { forwardRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import appConfig from 'appconfig';
import CryptoJS from 'crypto-js';
import jwtDecode from 'jwt-decode';

import useAuth from 'hooks/useAuth';

export const varIsDefined = (varName) => {
  return varName !== undefined;
};

export const varIsEmpty = (varName) => {
  return varName === null || varName === '' ? true : false;
};

export const Slugify = (string) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (
    string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      // eslint-disable-next-line
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      // eslint-disable-next-line
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
};

export const FileTypeDesc = (fileType) => {
  if (fileType === 'application/pdf') {
    return 'Portable Document Format (PDF)';
  } else if (fileType.split('/').includes('image')) {
    return 'Image File';
  } else if (fileType === 'application/msword') {
    return 'Word Document';
  } else if (fileType.split('.').includes('document')) {
    return 'Word Document';
  } else if (fileType.split('/').includes('audio')) {
    return 'Audio File';
  } else if (fileType.split('/').includes('video')) {
    return 'Video File';
  } else {
    return 'Unknown File Type';
  }
};

export const encryptData = (plainText, cipherKey = appConfig.ENC_DEC_PHRASE) => {
  const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(plainText), cipherKey).toString();
  return encryptedData;
};

export const decryptData = (encryptedText, decipherKey = appConfig.ENC_DEC_PHRASE) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, decipherKey);
  const decrptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decrptedData;
};

export const isValidToken = (userToken) => {
  if (!userToken) {
    return false;
  }
  const decodedToken = jwtDecode(userToken);
  const currentTime = Date.now() / 1000;
  // console.log(decodedToken.exp, currentTime);
  return decodedToken.exp > currentTime;
};

export const userHasDbRole = (dbRole, userRoles) => {
  if (!dbRole || !Array.isArray(userRoles) || userRoles.length < 1) {
    return false;
  }
  let hasRole = false;
  for (let i = 0; i < userRoles.length; i++) {
    if (dbRole === 'ADD' && userRoles[i].dbaddStatus === 1) {
      hasRole = true;
      break;
    }
    if (dbRole === 'EDIT' && userRoles[i].dbaddStatus === 1) {
      hasRole = true;
      break;
    }
    if (dbRole === 'DELETE' && userRoles[i].dbdeleteStatus === 1) {
      hasRole = true;
      break;
    }
    if (dbRole === 'SUCONTROL' && userRoles[i].dbchangeStatus === 1) {
      hasRole = true;
      break;
    }
  }
  return hasRole;
};

export const usePageOptions = () => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  let pageOptions = {
    pageTitle: 'Home',
    pageMainMenu: 'Main Menu',
    pageSubMenu: 'Sub Menu',
    pageTask: 'Task Name',
    routeSegments: []
  };
  const matched = user.roleTasks.find((task) => task.taskUrl === pathname);
  if (matched !== undefined) {
    pageOptions['pageTitle'] = matched.taskTitle;
    pageOptions['pageMainMenu'] = matched.mainMenuName;
    pageOptions['pageSubMenu'] = matched.subMenuName;
    pageOptions['pageTask'] = matched.taskName;
    pageOptions['routeSegments'].push(
      { name: matched.mainMenuName, path: '#' },
      { name: matched.subMenuName, path: '#' },
      { name: matched.taskName }
    );
  }

  return pageOptions;
};

export const useIsServiceManager = () => {
  const { user } = useAuth();
  const matched = user.userRoles.find((role) => Number(role.approle) === 1);
  return matched !== undefined;
};

export const useIsCashier = () => {
  const { user } = useAuth();
  const matched = user.userRoles.find((role) => Number(role.approle) === 5);
  return matched !== undefined;
};

export const useDocumentTitle = (title = 'Home') => {
  useEffect(() => {
    document.title = `${appConfig.productName}::${title}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export const to12HourFormat = (time) => {
  let timeSplit = time.split(':');
  let hours = Number(timeSplit[0]);
  let minutes = Number(timeSplit[1]);
  let amOrPm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12 || 12;
  return hours + ':' + minutes + ' ' + amOrPm;
};

export const toNairaCurrencyFormat = (value) =>
  new Intl.NumberFormat('en-NG', {
    style: 'currency',
    currency: 'NGN'
  }).format(value);

export const numberToMoneyFormat = (number, decimal = 2) =>
  parseFloat(number).toLocaleString('us-NG', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal
  });

// export const numberToMoneyFormat = (number, decimal) => {
//   return parseFloat(parseFloat(number).toFixed(decimal)).toLocaleString('en-NG', {
//     useGrouping: true
//   });
// };

export const passwordStrength = (password) => {
  if (password === '' || password === null || password === undefined) {
    return 'You have not entered a new password';
  } else if (password.length < 8) {
    return 'The password you supplied does not meet the minimum cretarial of a strong password. A strong password should contain at least 8 characters in length';
  } else if (!/[a-z]/.test(password)) {
    return 'The password you supplied does not meet the minimum cretarial of a strong password. A strong password should contain at least 1 lowercase alphabetical character';
  } else if (!/[A-Z]/.test(password)) {
    return 'The password you supplied does not meet the minimum cretarial of a strong password. A strong password should contain at least 1 uppercase alphabetical character';
  } else if (!/[0-9]/.test(password)) {
    return 'The password you supplied does not meet the minimum cretarial of a strong password. A strong password should contain at least 1 numeric character';
  } else if (!/[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)) {
    return 'The password you supplied does not meet the minimum cretarial of a strong password. A strong password should contain at least 1 special character';
  }
  return 'good';
};

/**
 * Return full url or path of a media file. For example
 * https://thimadoos.com.ng/me62933a2951ef01f4eafd9bdf4d3cd2f0dia/emp_pics/26ff69e855d2489c9187684ccf2a6349.jpg
 * This function assumes that a full media url or path is divided into four parts: The host (https://thimadoos.com.ng),
 * media url from backend (/me62933a2951ef01f4eafd9bdf4d3cd2f0dia), media folder (emp_pics) and
 * the media file name (26ff69e855d2489c9187684ccf2a6349.jpg)
 * @param  {String} shortMediaPath Media path from backend
 * @return {String}      The full media url/path
 */
export const getMediaFullPath = (shortMediaPath) => {
  let mediaData = [appConfig.mediaBaseUrl, appConfig.mediaUrl];
  let splitShortMediaPath = shortMediaPath?.charAt(0) === '/' ? String(shortMediaPath.substring(1)).split('/') : String(shortMediaPath).split('/');
  if (splitShortMediaPath.length === 1) {
    mediaData.push(splitShortMediaPath[0]);
  } else if (splitShortMediaPath.length > 1) {
    mediaData.push(splitShortMediaPath[splitShortMediaPath.length - 2]);
    mediaData.push(splitShortMediaPath[splitShortMediaPath.length - 1]);
  }
  return mediaData.join('/');
};

export const ucfirst = (stringChars) => {
  if (varIsDefined(stringChars) && !varIsEmpty(stringChars) && stringChars.length > 0) {
    return stringChars.charAt(0).toUpperCase() + stringChars.slice(1).toLowerCase();
  }
  return '';
};

export const stringifyErrorMessages = (errorMsgsObj = {}, fieldsDesc = {}) => {
  // {
  //     "custEmail": [
  //         "Customer with this email address already exists."
  //     ],
  //     "custPhoneno": [
  //         "Customer with this phone number already exists."
  //     ]
  // }
  let errMessage = [];
  if (Object.keys(errorMsgsObj).length > 0) {
    Object.keys(errorMsgsObj).forEach(function (key) {
      errMessage.push(key in fieldsDesc ? fieldsDesc[key] + ' : ' + errorMsgsObj[key] : errorMsgsObj[key]);
    });
  }
  return errMessage.join('###');
};

export const arrayDuplicateElements = (inputArray = []) => {
  const duplicateArray = inputArray.filter((arrayElement, arrayIndex) => inputArray.indexOf(arrayElement) !== arrayIndex);
  return Array.from(new Set(duplicateArray));
};

export const prodAttrsAndValsDesc = (product_inventory_attrvals) => {
  const prodAttrsAndVals = product_inventory_attrvals.map((prodinv_attrval) => {
    return prodinv_attrval.prodattrName + ' = ' + prodinv_attrval.attrvalData;
  });
  return prodAttrsAndVals.length > 0 ? '(' + prodAttrsAndVals.join(', ') + ')' : '';
};

export const orderReceiptNo = (order_payment) => {
  const receiptNo = !varIsEmpty(order_payment) ? order_payment.map((order_pay) => order_pay.payNo) : ['#NoPay'];
  return receiptNo.join('_');
};

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  const idArray = props.id.split('-');
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      valueIsNumericString
      prefix={appConfig.CURRENCY_SYMBOLS.includes(idArray[idArray.length - 1]) ? idArray[idArray.length - 1] : ''}
    />
  );
});

NumericFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};
