import { decryptData } from 'views/commons/commonhooks';
import {
  ADD_PRODUCT_TO_CART,
  DELETE_PRODUCT_FROM_CART,
  GET_BRAND_LIST,
  GET_CART_LIST,
  GET_CATEGORY_LIST,
  GET_PRODUCT_LIST_RETAIL,
  GET_PRODUCT_LIST_WHOLESALE,
  GET_PRODUCT_LIST_RETAIL_ADMIN,
  GET_PRODUCT_LIST_WHOLESALE_ADMIN,
  GET_PRODUCT_LIST_CHECKOUT,
  GET_RATING_LIST,
  UPDATE_CART_AMOUNT,
  EMPTY_CART,
  ADD_ORDER_TO_EDIT,
  REMOVE_ORDER_TO_EDIT
} from '../actions/EcommerceActions';

const initialState = {
  productListRetail: [],
  productListWholesale: [],
  productListRetailAdmin: [],
  productListWholesaleAdmin: [],
  productListCheckout: [],
  cartList: localStorage.getItem('CTL46UB5C845ZR6') ? JSON.parse(decryptData(localStorage.getItem('CTL46UB5C845ZR6'))) : [],
  orderToEdit: localStorage.getItem('ODT46OE5D845IT6') ? JSON.parse(decryptData(localStorage.getItem('ODT46OE5D845IT6'))) : {}
};

const EcommerceReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST_RETAIL: {
      return {
        ...state,
        productListRetail: [...action.payload]
      };
    }
    case GET_PRODUCT_LIST_WHOLESALE: {
      return {
        ...state,
        productListWholesale: [...action.payload]
      };
    }

    case GET_PRODUCT_LIST_RETAIL_ADMIN: {
      return {
        ...state,
        productListRetailAdmin: [...action.payload]
      };
    }
    case GET_PRODUCT_LIST_WHOLESALE_ADMIN: {
      return {
        ...state,
        productListWholesaleAdmin: [...action.payload]
      };
    }
    case GET_PRODUCT_LIST_CHECKOUT: {
      return {
        ...state,
        productListCheckout: [...action.payload]
      };
    }
    case GET_CATEGORY_LIST: {
      return {
        ...state,
        categoryList: [...action.payload]
      };
    }
    case GET_RATING_LIST: {
      return {
        ...state,
        ratingList: [...action.payload]
      };
    }
    case GET_BRAND_LIST: {
      return {
        ...state,
        brandList: [...action.payload]
      };
    }
    case GET_CART_LIST: {
      return {
        ...state,
        cartList: [...action.payload]
      };
    }
    case ADD_PRODUCT_TO_CART: {
      const item = action.payload;
      let prodstk = state.cartList.find((pstk) => pstk.pstockId === item.pstockId);
      if (prodstk !== undefined) {
        const updatedCartList = state.cartList.map((pstk) => {
          if (pstk.pstockId === item.pstockId) {
            return {
              ...pstk,
              pstockQty: pstk.pstockQty + item.pstockQty
            };
          } else {
            return pstk;
          }
        });
        return {
          ...state,
          cartList: [...updatedCartList]
        };
      } else {
        return {
          ...state,
          cartList: [...state.cartList, item]
        };
      }
    }
    case DELETE_PRODUCT_FROM_CART: {
      const payload = action.payload;
      const updatedCartList = state.cartList.filter((pstk) => pstk.pstockId !== payload.pstockId);
      return {
        ...state,
        cartList: [...updatedCartList]
      };
    }
    case UPDATE_CART_AMOUNT: {
      const payload = action.payload;
      let updatedCartList = [];
      for (let index = 0; index < state.cartList.length; index++) {
        const pstk = state.cartList[index];
        if (pstk.pstockId === payload.pstockId) {
          //if quantity is less than 1 then remove item from cart by skipping current iterations
          if (payload.pstockQty < 1) continue;
          updatedCartList = [
            ...updatedCartList,
            {
              ...pstk,
              pstockQty: payload.pstockQty > pstk.pstockUnits ? pstk.pstockUnits : payload.pstockQty
            }
          ];
        } else {
          updatedCartList = [...updatedCartList, pstk];
        }
      }
      return {
        ...state,
        cartList: [...updatedCartList]
      };
    }
    case EMPTY_CART: {
      return {
        ...state,
        cartList: []
      };
    }
    case ADD_ORDER_TO_EDIT: {
      return {
        ...state,
        orderToEdit: { ...action.payload }
      };
    }
    case REMOVE_ORDER_TO_EDIT: {
      return {
        ...state,
        orderToEdit: {}
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
};

export default EcommerceReducer;
