import { enqueueSnackbar } from 'notistack';

import { encryptData, varIsEmpty } from 'views/commons/commonhooks';

export const GET_PRODUCT_LIST_RETAIL = 'GET_PRODUCT_LIST_RETAIL';
export const GET_PRODUCT_LIST_WHOLESALE = 'GET_PRODUCT_LIST_WHOLESALE';
export const GET_PRODUCT_LIST_RETAIL_ADMIN = 'GET_PRODUCT_LIST_RETAIL_ADMIN';
export const GET_PRODUCT_LIST_WHOLESALE_ADMIN = 'GET_PRODUCT_LIST_WHOLESALE_ADMIN';
export const GET_PRODUCT_LIST_CHECKOUT = 'GET_PRODUCT_LIST_CHECKOUT';

export const GET_CART_LIST = 'GET_CART_LIST';
export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_RATING_LIST = 'GET_RATING_LIST';
export const GET_BRAND_LIST = 'GET_BRAND_LIST';

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART';
export const UPDATE_CART_AMOUNT = 'UPDATE_CART_AMOUNT';
export const DELETE_PRODUCT_FROM_CART = 'DELETE_PRODUCT_FROM_CART';
export const EMPTY_CART = 'EMPTY_CART';

export const ADD_ORDER_TO_EDIT = 'ADD_ORDER_TO_EDIT';
export const REMOVE_ORDER_TO_EDIT = 'REMOVE_ORDER_TO_EDIT';

export const addProductToCart = (prodstk, pstockQty) => (dispatch, getState) => {
  const cartList = getState().ecommerce.cartList;
  const getProdAttrsAndVals = (product_inventory_attrvals) => {
    const prodAttrsAndVals = product_inventory_attrvals.map((prodinv_attrval) => {
      return prodinv_attrval.prodattrName + ': ' + prodinv_attrval.attrvalData;
    });
    return prodAttrsAndVals.length > 0 ? prodAttrsAndVals.join(', ') : '';
  };

  if (cartList.length > 0 && cartList[0].branch !== prodstk.branch) {
    // danger: product from a different branch are in the shopping cart. Alert user and empty shopping cart
    // dispatch(emptyCart());
    // send message to user
    enqueueSnackbar('You can not have products from more than one store in the same cart.', {
      autoHideDuration: 8000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      variant: 'warning'
    });
    // enqueueSnackbar('Your shopping cart has been reset.', {
    //   autoHideDuration: 9000,
    //   anchorOrigin: {
    //     vertical: 'top',
    //     horizontal: 'right'
    //   },
    //   variant: 'warning'
    // });
  } else if (cartList.length > 0 && cartList[0].storeType !== prodstk.storeType) {
    // danger: wholesale products can not mix with retail product in shopping cart. Alert user and empty shopping cart
    // dispatch(emptyCart());
    // send message to user
    enqueueSnackbar('You can not have wholesale and retail products in the same cart.', {
      autoHideDuration: 8000,
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      variant: 'warning'
    });
    // enqueueSnackbar('Your shopping cart has been reset.', {
    //   autoHideDuration: 9000,
    //   anchorOrigin: {
    //     vertical: 'top',
    //     horizontal: 'right'
    //   },
    //   variant: 'warning'
    // });
  } else {
    const payloadData = {
      pstockId: prodstk.pstockId,
      prodinv: prodstk.prodinv,
      pstockSku: prodstk.pstockSku,
      pstockItemId: prodstk.prodId,
      pstockItemCatId: prodstk.prodcatId,
      pstockName: prodstk.prodName,
      pstockAttrDesc: !varIsEmpty(prodstk.product_inventory_attrval) ? getProdAttrsAndVals(JSON.parse(prodstk.product_inventory_attrval)) : '',
      pstockImage: !varIsEmpty(prodstk.prodMedia) ? JSON.parse(prodstk.prodMedia)[0].pmediaFile : prodstk.defaultImage,
      pstockPrice: prodstk.storeType === 'W' ? prodstk.wholesalePrice : prodstk.retailPrice,
      pstockStorePrice: prodstk.storeType === 'W' ? prodstk.wholesaleStorePrice : prodstk.retailStorePrice,
      pstockUnits: prodstk.pstockUnits,
      pstockQty: pstockQty,
      branch: prodstk.branch,
      storeType: prodstk.storeType
    };
    // update cart list in redux store
    dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: 'oderitemId' in prodstk ? { ...payloadData, ...{ oderitemId: prodstk.oderitemId } } : { ...payloadData }
    });
    // save cartList to storage for retriver in case user perform hard refresh on the page
    localStorage.setItem('CTL46UB5C845ZR6', encryptData(JSON.stringify(getState().ecommerce.cartList)));
  }
};

export const deleteProductFromCart = (pstockId) => (dispatch, getState) => {
  dispatch({
    type: DELETE_PRODUCT_FROM_CART,
    payload: { pstockId: pstockId }
  });
  //update local storage
  localStorage.setItem('CTL46UB5C845ZR6', encryptData(JSON.stringify(getState().ecommerce.cartList)));
};

export const updateCartAmount = (pstockId, pstockQty) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CART_AMOUNT,
    payload: {
      pstockId: pstockId,
      pstockQty: pstockQty
    }
  });
  //update local storage
  localStorage.setItem('CTL46UB5C845ZR6', encryptData(JSON.stringify(getState().ecommerce.cartList)));
};

export const emptyCart = () => (dispatch, getState) => {
  //remove cart list from local storage
  localStorage.removeItem('CTL46UB5C845ZR6');
  // remove disount info from local storage
  localStorage.removeItem('D1S46UC5O845ZT6');
  // dispatch empty cart
  dispatch({
    type: EMPTY_CART,
    payload: {}
  });
};

export const addOrderToEdit = (order) => (dispatch, getState) => {
  dispatch({
    type: ADD_ORDER_TO_EDIT,
    payload: { ...order }
  });
  // save order info to storage for retriever in case user perform hard refresh on the page
  localStorage.setItem('ODT46OE5D845IT6', encryptData(JSON.stringify(getState().ecommerce.orderToEdit)));
};

export const removeOrderToEdit = () => (dispatch) => {
  // remove order to edit info
  localStorage.removeItem('ODT46OE5D845IT6');
  dispatch({
    type: REMOVE_ORDER_TO_EDIT,
    payload: {}
  });
};
